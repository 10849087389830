import styled from "styled-components";

export const InfoContainer = styled.div`
  color: var(--white);
  background: ${({ lightBg }) => (lightBg ? "var(--white)" : "var(--black)")};

  @media screen and (max-width: 768px) {
    padding: 10px 0;
  }
  
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 24px;
  justify-content: center;
`;

export const InfoRow = styled.div`
  align-items: center;
`;

export const TextWrapper = styled.div`
  max-width: 800px;
  padding-top: 0;
  padding-bottom: 20px;
`;

export const TopLine = styled.p`
  color: var(--blue);
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
  text-align: center;
  font-size: 2.5rem;
  padding: 24px;

  @media screen and (max-width: 350px) {
    font-size: 1.9rem;
  }
`;

export const Subtitle = styled.p`
  max-width: 600px;
  margin-bottom: 35px;
  font-size: 18px;
  text-align: center;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "var(--black)" : "var(--white)")};

  @media screen and (max-width: 350px) {
    font-size: 14px;
  }
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;
