import React, { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Sidebar from "../components/SideBar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AboutUs from "../components/AboutUs";
import { aboutObjOne } from "../components/AboutUs/Data";
import Services from "../components/TeamMembers";

const AboutUsPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  console.log(aboutObjOne["description"]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Us</title>
          <meta
            name="description"
            content="We are a software development and technology consulting firm specializing in helping small businesses maximize technology."
          />
          <link rel="canonical" href="http://maxautomate.com/aboutus" />
        </Helmet>
      </HelmetProvider>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} offset={false} />

      <AboutUs {...aboutObjOne} />
      <Services />
      <Footer />
    </>
  );
};

export default AboutUsPage;
