import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
export const Nav = styled.nav`
  background: ${({ scrollNav, homePage }) =>
    scrollNav || !homePage ? "var(--black)" : "transparent"};
  height: 80px;
  margin-top: ${({ homePage }) => (homePage ? "-80px" : "0px")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainter = styled.div`
    display:flex ;
    justify-content: space-evenly;
    align-items: center;
    height:80px;
    z-index: 7;
    width:100%;
    padding: 0 24px;
    max-width:1100px;
    
`;

export const NavLogo = styled(LinkR)``;
export const NavLogoImg = styled.img`
  height: 35px;
  vertical-align: bottom;
  margin-left: 24px;
  text-decoration: none;

  cursor: pointer;
  @media screen and (max-width: 868px) {
    height: 25px;
  }
`;
export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: var(--white);
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinks = styled(LinkR)`
  color: var(--white);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid var(--blue);
  }
`;

export const NavLinksNotHome = styled.p`
  color: var(--black);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;

  /* cursor: none; */
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: var(--blue);
  white-space: nowrap;
  padding: 10px 22px;
  color: var(--black);
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--black);
`;
