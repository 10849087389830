import React from "react";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  TopLineMax,
  ImgWrap,
  Img,
} from "./InfoElements";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
}) => {
  return (
    <>
      <InfoContainer lightBg={!lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column2>
              <TextWrapper>
                <TopLine lowercase={true}>
                  <TopLineMax lightText={!lightText} proper={false}>
                    max(
                  </TopLineMax>
                  {" " + topLine + " "}
                  <TopLineMax proper={false} lightText={!lightText}>
                    )
                  </TopLineMax>
                </TopLine>
                <Heading lightText={!lightText}>{headline}</Heading>
                <Subtitle darkText={!darkText}>{description}</Subtitle>
              </TextWrapper>
            </Column2>

            <Column1>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column1>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
