import React, { useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Sidebar from "../components/SideBar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import TeamMember from "../components/TeamMemberProfile/teammembers";
import { employeeList, employeeOrder } from "../components/TeamMembers/Data";

const TeamMemberPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const params = useParams().teammemberid;
  if (!(params in employeeOrder)) {
    return <Navigate to="/" />;
  }
  console.log(params);
  var order = employeeOrder[params];
  var data = employeeList[order][params];
  console.log(data);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{data.name}</title>
          <meta
            name="description"
            content={"Learn more about our " + data.position + ", " + data.name}
          />
          <link
            rel="canonical"
            href={"http://maxautomate.com/aboutus/" + params}
          />
        </Helmet>
      </HelmetProvider>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} offset={false} />

      <TeamMember data={data} params={params} />
      <Footer />
    </>
  );
};

export default TeamMemberPage;
