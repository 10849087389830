import React, { useState } from "react";
import Sidebar from "../components/SideBar";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  homeObjOne,
  homeObjThree,
  homeObjTwo,
  homeObjFour,
} from "../components/InfoSection/Data";

import Footer from "../components/Footer";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>max( automate )</title>
          <meta
            name="description"
            content="We are a technology consulting firm located in Phoenix, Arizona that specializes in helping small businesses."
          />
          <link rel="canonical" href="http://maxautomate.com/" />
        </Helmet>
      </HelmetProvider>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} homePage={true} />
      <HeroSection />
      <div id="process">
        <InfoSection {...homeObjOne} />
        <InfoSection {...homeObjTwo} />
        <InfoSection {...homeObjThree} />
        <InfoSection {...homeObjFour} />
      </div>

      <Footer />
    </>
  );
};

export default Home;
