import React, { useState } from "react";
import Sidebar from "../components/SideBar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUsForm";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <meta http-equiv="Content-type" content="text/html; charset=UTF-8" />
          <title>Contact Us</title>
          <meta
            name="description"
            content="Interested in learning more, contact us to see how we can help."
          />
          <link rel="canonical" href="http://maxautomate.com/contactus" />
        </Helmet>
      </HelmetProvider>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} offset={false} />

      <ContactUs />
      <Footer />
    </>
  );
};

export default Home;
