import React from "react";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  TextWrapper,
  TopLine,
  Subtitle,
  ImgWrap,
  Img,
} from "./AboutusElements";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,

  darkText,
  description,

  img,
  alt,
}) => {
  return (
    <>
    <body bgcolor="#000708">
        <Img src={img} alt={alt} />
    </body>
      <InfoContainer lightBg={false} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <TextWrapper>
              <TopLine>{topLine}</TopLine>
              <Subtitle darkText={false}>{description}</Subtitle>
            </TextWrapper>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
