import React, { useEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import { FaBars } from "react-icons/fa";
import logoWhite from "../../images/logo-white.png";
import {
  Nav,
  NavbarContainter,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  NavLogoImg,
  NavLogo,
} from "./NarbarElements";

const Navbar = ({ toggle, homePage }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <Nav scrollNav={scrollNav} homePage={homePage}>
        <NavbarContainter>
          <NavLogo to="/" onClick={toggleHome}>
            <NavLogoImg src={logoWhite} alt="logo" onClick={toggleHome} />
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="/"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-80}
              >
                Home
              </NavLinks>
            </NavItem>
            <NavItem>
              {/* <NavLinks
                  to="services"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Services
                </NavLinks> */}
            </NavItem>
            <NavItem>
              <NavLinks
                to="/aboutus"
                smooth={"true"}
                duration={500}
                spy="true"
                exact="true"
                offset={-80}
              >
                About Us
              </NavLinks>
            </NavItem>
            <NavBtn>
              <NavBtnLink to="/contactus">Contact Us</NavBtnLink>
            </NavBtn>
          </NavMenu>
        </NavbarContainter>
      </Nav>
    </>
  );
};

export default Navbar;
