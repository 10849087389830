export const employeeList = [
  {
    "ivan-lopez": {
      lightBg: false,
      imgStart: true,
      lightText: true,
      darkText: false,
      name: "Ivan Lopez",
      img: require("../../images/Ivan profile.svg").default,
      position: "Founder",
      background:
        "Ivan founded max( automate ) in May 2021 after working for over 6 years in the financial services industry. He has experience ranging from mom-and-pop wealth managers to multinational finance firms as an analyst and software developer. He has saved businesses countless hours by maintaining and creating systems that automate processes. Using his education in both business and technology, Ivan develops systems for businesses that provide clarity and structure.",
      organizations: [
        "Cognitive Science Society Member",
        "The Arizona Center for Nature Conservation Volunteer",
      ],
      education: [
        "M.S. in Computer Science | Georgia Tech (Dec '23)",
        "B.S. in Business Data Analytics | Arizona State",
        "B.S. in Finance | Arizona State",
      ],
      alt: "Ivan Lopez",
    },
  },

  {
    "cj-concepcion": {
      lightBg: false,
      imgStart: false,
      lightText: true,
      darkText: false,
      name: "C.J. Concepcion",
      img: require("../../images/CJ profile.svg").default,
      position: "Operations Manager",
      background:
        "C.J. joined max( automate ) in July 2022. Her time as a board member of multiple non-profits developed her skills in fufilling an organization's administrative and financial needs. She has over 2 years of experience as a Project Manager and Head of Logistics of a private international merchandise distributor. C.J. also ensures the projects we develop for businesses meet deadlines with timeliness and consistency.",
      organizations: ["Filipino American Youth Association"],
      education: ["B.F.A in Art | Arizona State"],
      alt: "CJ Concepcion",
    },
  },
];

export const employeeOrder = {
  "ivan-lopez": 0,
  "cj-concepcion": 1,
};
