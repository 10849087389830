import React from "react";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Subtitle,
  ImgWrap,
  Img,
} from "../InfoSection/InfoElements";

const TeamMember = ({ data, params }) => {
  console.log(data);
  return (
    <>
      <InfoContainer lightBg={data.lightBg} id={params}>
        <InfoWrapper>
          <InfoRow imgStart={data.imgStart}>
            <Column2>
              <TextWrapper>
                <TopLine proper={false} lightText={data.lightText}>
                  {data.name}
                </TopLine>
                <b>
                  <Subtitle darkText={data.darkText}>{data.position}</Subtitle>
                </b>
                <TopLine lightText={data.lightText}>Background</TopLine>
                <Subtitle darkText={data.darkText}>{data.background}</Subtitle>
                <TopLine lightText={data.lightText}>Education</TopLine>
                {data.education.map((e) => {
                  return (
                    <>
                      <Subtitle darkText={false}>
                        {e}
                      </Subtitle>
                    </>
                  );
                })}
                <TopLine lightText={data.lightText} lowercase={false}>
                  Current Professional & Volunteer Organizations
                </TopLine>
                {data.organizations.map((o) => {
                  return (
                    <>
                      <Subtitle darkText={false}>
                       {o}
                      </Subtitle>
                    </>
                  );
                })}
              </TextWrapper>
            </Column2>
            <Column1>
              <ImgWrap>
                <Img src={data.img} alt={data.alt} />
              </ImgWrap>
            </Column1>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default TeamMember;
