import React from "react";
import logoWhite from "../../images/logo-white.png";
import { FaYoutube, FaLinkedin } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinkTile,
  FooterLink,
  FooterLinksWrapper,
  FooterLinkItems,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIconLink,
  SocialIcons,
  FooterLinkText,
  FooterLinkExternal,
} from "./FooterElements";
const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <FooterContainer>
        <FooterWrap>
          <FooterLinksContainer>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTile>Hours and Location</FooterLinkTile>
                <FooterLinkText>MON-FRI 10:00 - 6:00</FooterLinkText>
                <FooterLinkText>Phoenix, Arizona</FooterLinkText>
                <FooterLinkExternal href="tel:6027180713">
                  602.718.0713
                </FooterLinkExternal>
                <FooterLinkExternal href="mailto:info@maxautomate.com">
                  info@maxautomate.com
                </FooterLinkExternal>
              </FooterLinkItems>

              <FooterLinkItems>
                <FooterLinkTile>Legal</FooterLinkTile>
                <FooterLinkExternal href="https://www.termsfeed.com/live/a3ecf64f-316e-413a-aa7b-8e0665e06ff1">
                  Terms of Use
                </FooterLinkExternal>
                <FooterLinkExternal href="https://www.termsfeed.com/live/d91e7748-f4eb-4610-9435-85ab1fe1cfae">
                  Privacy Policy
                </FooterLinkExternal>
              </FooterLinkItems>
              <FooterLinkItems>
                <FooterLinkTile>About Us</FooterLinkTile>
                <FooterLink to="/careers">Careers</FooterLink>
              </FooterLinkItems>
            </FooterLinksWrapper>
          </FooterLinksContainer>
          <SocialMedia>
            <SocialMediaWrap>
              <SocialLogo to="/" onClick={toggleHome}>
                <img src={logoWhite} height={30} alt="logo" />
              </SocialLogo>
              <WebsiteRights>
                max( automate ) is a dba of <br />
                MAX( AUTOMATE ), LLC <br />
                {" " + new Date().getFullYear()}
              </WebsiteRights>
              <SocialIcons>
                <SocialIconLink href="https://www.linkedin.com/company/maxautomate/" target="_blank" aria-label="LinkedIn">
                  <FaLinkedin />
                </SocialIconLink>
              </SocialIcons>
            </SocialMediaWrap>
          </SocialMedia>
        </FooterWrap>
      </FooterContainer>
    </>
  );
};

export default Footer;
