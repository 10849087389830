export const homeObjOne = {
  id: "migrate",
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: "migrate",
  headline: "Strategically Upgrade From Outdated Tech",
  description:
    "Migrating clients away from legacy systems, software, and procedures towards more competitive technology",
  buttonLabel: "Get started",
  imgStart: false,
  img: require("../../images/migrate.svg").default,
  alt: "migrate",
  dark: true,
  primary: true,
  darkText: true,
};
export const homeObjTwo = {
  id: "integrate",
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  topLine: "integrate",
  headline: "Connect, Update, & Maintain Automatically",
  description:
    "Increasing efficiency, speed, and accuracy by integrating systems which reduces manual double entry and data errors between systems",
  buttonLabel: "Learn More",
  imgStart: true,
  img: require("../../images/integrate.svg").default,
  alt: "integrate",
  dark: false,
  primary: false,
  darkText: false,
};
export const homeObjThree = {
  id: "create",
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: "create",
  headline: "When Out-Of-The-Box Is Not Enough",
  description:
    "Creating custom software and solutions to automate your unique business processes",
  buttonLabel: "Start Now",
  imgStart: false,
  img: require("../../images/create.svg").default,
  alt: "code",
  dark: true,
  primary: true,
  darkText: true,
};

export const homeObjFour = {
  id: "educate",
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  topLine: "educate",
  headline: "Comfortable With Tech-Transformation",
  description:
    "Educating end-users through training supplemented with thorough documentation ensures they feel empowered by new evolving automated processes",
  buttonLabel: "Start Now",
  imgStart: true,
  img: require("../../images/educate.svg").default,
  alt: "educate",
  dark: false,
  primary: false,
  darkText: false,
};
