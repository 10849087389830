import React, { useState } from "react";
import Sidebar from "../components/SideBar";
import Navbar from "../components/Navbar";
import Careers from "../components/Careers";
import Open from "../components/Open";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Footer from "../components/Footer";

const CareersPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Careers</title>
          <meta
            name="description"
            content="Interested in joining our team? Find out more."
          />
          <link rel="canonical" href="http://maxautomate.com/careers" />
        </Helmet>
      </HelmetProvider>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} homePage={true} />
      <Careers />
      <Open />
      <Footer />
    </>
  );
};

export default CareersPage;
// Test
