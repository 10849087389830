import React from "react";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServiceCardWrap,
  ServicesH2,
  ServicesP,
} from "./OpenElements";
const OpenPositions = () => {
  return (
    <>
      <ServicesContainer id="open">
        <ServicesH1>Open Positions</ServicesH1>
        <ServicesWrapper>
          <ServiceCardWrap to="">
            <ServicesCard>
              <ServicesH2>No open positions</ServicesH2>
              <ServicesP
                onClick={() =>
                  (window.location = "mailto:careers@maxautomate.com")
                }
              >
                Please email your resume to careers@maxautomate.com.
              </ServicesP>
            </ServicesCard>
          </ServiceCardWrap>
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
};

export default OpenPositions;
