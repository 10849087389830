export const benefits = [
  {
    basics: {
      header: "The Basics",
      list: [
        "Health Insurance (with HSA options)",
        "Dental Insurance (with Ortho)",
        "EAP Services",
        "Vision Insurance",
        "Short-Term Disability Insurance",
        "Long-Term Disability Insurance",
        "$50k Life Insurance",
      ],
      image: require("../../images/basics.svg").default,
    },
  },

  {
    educations: {
      header: "Career & Eduction Advancement",
      list: [
        "Discounted Tuition at University of Arizona",
        "Paid Certifications & Study Materials",
        "Events & Conferences Stipend",
      ],
      image: require("../../images/education.svg").default,
    },
  },

  {
    culture: {
      header: "Company Culture",
      list: [
        "Unlimited PTO",
        "Paid volunteer hours",
        "Gym membership stipend",
        "100% remote",
        "Helping small businesses grow",
      ],
      image: require("../../images/culture.svg").default,
    },
  },
];
