import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages";
import ContactUs from "./pages/contactus";
import AboutUsPage from "./pages/aboutus";
import TeamMember from "./pages/teammembers";
import ScrollToTop from "./components/ScrollToTop";
import CareersPage from "./pages/careers";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/contactus" element={<ContactUs />} />
        <Route exact path="/aboutus" element={<AboutUsPage />} />
        <Route exact path="/careers" element={<CareersPage />} />
        <Route path="/aboutus/:teammemberid" element={<TeamMember />} />
        <Route path="/*" element={<Home />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
