import styled from "styled-components";

export const ContactContainter = styled.div`
  background-color: var(--black);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 900px;
`;

export const ContactWrapper = styled.div`
  background-color: var(--white);
  width: 100%;
`;

export const ContactH1 = styled.h1`
  background-color: var(--black);
  color: var(--blue);
  text-align: center;
  width: 100%;
  color: var(--blue);
  padding: 24px;
`;

export const ContactForm = styled.form`
  background-color: var(--black);
  color: var(--blue);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
`;

export const ContactFormInput = styled.input`
  width: 50%;
  padding: 1.2rem;
  color: var(--blue);
  background-color: var(--white);
  outline: none;
  border: none;
  border-radius: 8px;
  margin-top: 1rem;
`;

export const ContactFormTextArea = styled.textarea`
  width: 50%;
  padding: 1.2rem;
  color: var(--blue);
  background-color: var(--white);
  outline: none;
  border: none;
  border-radius: 8px;
  margin-top: 1rem;
  min-height: 250px;
  resize: vertical;
`;

export const ContactFormSubmit = styled.input`
  /* margin-top: 20px; */
  background-color: var(--blue);
  width: 25%;
  outline: none;
  border: none;
  white-space: nowrap;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: underline;
  padding: 12px 30px;
  color: var(--black);
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
