export const aboutObjOne = {
  id: "about",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "About Us",
  description:
    "Located in Phoenix, Arizona, we are a software development and technology consulting firm specializing in helping small businesses maximize technology. Our primary focus is understanding technical and business problems to ensure we are not compounding an issue with technology. We are more than a temporary solution; we are partners who walk through your present and future technical needs, so your business can flourish. ",
  buttonLabel: "Start Now",
  imgStart: false,
  img: require("../../images/about us.svg").default,
  alt: "about",
  dark: false,
  primary: false,
  darkText: true,
};
