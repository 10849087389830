import React from "react";
import { employeeList } from "./Data";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServiceCardWrap,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";

const TeamMembers = () => {
  return (
    <>
      <ServicesContainer id="services">
        <ServicesH1>Meet Our Team</ServicesH1>
        <ServicesWrapper>
          {employeeList.map((employee) => {
            for (let key in employee) {
              // let key = employee;
              let temp = "/aboutus/" + key;
              return (
                <>
                  <ServiceCardWrap to={temp}>
                    <ServicesCard>
                      <ServicesIcon src={employee[key].img} alt={key} />
                      <ServicesH2>{employee[key].name}</ServicesH2>
                      <ServicesP>{employee[key].position}</ServicesP>
                    </ServicesCard>
                  </ServiceCardWrap>
                </>
              );
            }
          })}
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
};

export default TeamMembers;
