import React from "react";

import {
  ContactContainter,
  ContactH1,
  ContactForm,
  ContactFormInput,
  ContactFormSubmit,
  ContactWrapper,
  ContactFormTextArea,
} from "../ContactUsForm/ContactUseElements";

const ContactUs = () => {
  return (
    <>
      <ContactContainter>
        <ContactWrapper>
          <ContactH1>Contact Us</ContactH1>
          <ContactForm
            action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
            method="POST"
          >
            <ContactFormInput
              name="oid"
              type="hidden"
              value="00D8b0000029qTC"
            />
            <ContactFormInput
              name="retURL"
              type="hidden"
              value="http://maxautomate.com"
            />
            <ContactFormInput
              id="company"
              maxlength="40"
              name="company"
              size="20"
              type="text"
              placeholder="Company Name*"
              required
            />
            <br />

            <ContactFormInput
              id="first_name"
              maxlength="40"
              name="first_name"
              size="20"
              type="text"
              required
              placeholder="First Name*"
            />
            <ContactFormInput
              id="last_name"
              maxlength="80"
              name="last_name"
              size="20"
              type="text"
              required
              placeholder="Last Name*"
            />
            <br />

            <ContactFormInput
              id="email"
              maxlength="80"
              name="email"
              size="20"
              type="text"
              placeholder="Email*"
              required="true"
            />
            <br />

            <ContactFormInput
              id="phone"
              maxlength="40"
              name="phone"
              size="20"
              type="text"
              placeholder="Phone Number"
            />
            <br />

            <ContactFormTextArea
              name="description"
              placeholder="Description"
              rows="4"
            />
            <br />
            <ContactFormSubmit type="submit" name="submit" />
          </ContactForm>
        </ContactWrapper>
      </ContactContainter>
    </>
  );
};

export default ContactUs;
