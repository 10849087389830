import React from "react";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  Heading,
  Subtitle,
  ImgWrap,
  Img,
} from "../InfoSection/InfoElements";
import { benefits } from "./Data";

const Careers = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
  listIndent,
}) => {
  var invert = true;
  return (
    <>
      {benefits.map((benefit) => {
        for (let key in benefit) {
          let temp = benefit[key].list;
          invert = !invert;
          return (
            <>
              <InfoContainer lightBg={invert} key={key}>
                <InfoWrapper>
                  <InfoRow imgStart={invert}>
                    <Column2>
                      <TextWrapper>
                        <Heading lowercase={true}>
                          <Heading lightText={!invert}>
                            {benefit[key].header}
                          </Heading>
                        </Heading>
                        {temp.map((l) => {
                          return (
                            <>
                              <Subtitle darkText={invert} listIndent={true}>
                                {l}
                              </Subtitle>
                            </>
                          );
                        })}
                      </TextWrapper>
                    </Column2>
                    <Column1>
                      <ImgWrap>
                        <Img
                          src={benefit[key].image}
                          alt={alt}
                          imgSize="25vh"
                        />
                      </ImgWrap>
                    </Column1>
                  </InfoRow>
                </InfoWrapper>
              </InfoContainer>
            </>
          );
        }
        return <></>;
      })}
    </>
  );
};

export default Careers;
